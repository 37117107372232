import React from "react"
import styled from "styled-components"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <span>Elemental</span>
        <ul>
          <li>&copy; {(new Date()).getFullYear()} All Rights Reserved</li>
          <li>
            Content from <a href="https://wlfs.org" rel="noopener noreferrer" target="_blank">WLFS.org</a>
          </li>
          <li>
            Developed by <a href="mailto:hello@seasoft.io">Seasoft Limited</a>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>More Information</span>
        <ul>
          <li>
            <a href="mailto:support@elementalmusic.app">
              Email Us
            </a>
          </li>
          <li>
            <a href="/privacy">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/terms">
              Terms of Use
            </a>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Download the App</span>
        <ul>
          <li>Apple App Store</li>
          <li>Google Play Store</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Existing Users</span>
        <ul>
          <li>
            <a href="https://elementalmusic.statuspage.io" rel="noopener noreferrer" target="_blank">
              Service Status
            </a>
          </li>
          <li>
            <a href="https://portal.elementalmusic.app" rel="noopener noreferrer" target="_blank">
              Teacher Portal
            </a>
          </li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
      a {
        color: ${props => props.theme.color.black.regular};
        text-decoration: none;
      }
    }
  }
`

export default Footer
